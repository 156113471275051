import { Message } from "element-ui"
export function onTip(isFlag, is_member_by, context, not_buy_msg) {
  if (!context.$store.getters.token) {
    context.$router.pushToTab("/login")
    return true
  }
  if (!isFlag) {
    Message.error(not_buy_msg)
    return true
  } else if (!is_member_by) {
    Message.error(not_buy_msg)
    return true
  }
  else {
    return false
  }
}
