import http from "@/utils/http"

/**
 * 组合套餐列表
 * @param {object} params
 */
export function goodsPage(params) {
    return http({
        url: "/bundling/api/bundling/page",
        data: params
    })
}

export function joinCart(params) {
    return http({
        url: "/api/Cart/editBundling",
        data: params
    })
}

