<template>
    <div class="shareCounter" @click.stop="shareOclick">

        <template
             v-if="goodsSkuDetail.package_num<goodsSkuDetail.stock && goodsSkuDetail.is_member_by ? goodsSkuDetail.is_member_by :true"
        >
            <el-input-number
                @change="hangenumbers($event)"
                :value="numbers==0 ? 1 : numbers"
                :placeholder="String(numbers== 0 ? 1 : numbers)"
                :step="goodsSkuDetail.min_buy > 0  ? Number(goodsSkuDetail.min_buy) : 1"
                size="mini"
                :min="goodsSkuDetail.min_buy?goodsSkuDetail.min_buy:1"
                 step-strictly
                :max="goodsSkuDetail.stock"
                :disabled="$store.getters.token ? false :true"
            ></el-input-number>
        </template>
        <template v-else-if="!goodsSkuDetail.is_member_by">
            <el-button disabled size="small">{{sale_control_name?sale_control_name:'库存不足'}}</el-button>
        </template>
    </div>
</template>

<script>
import router from "../router"
export default {
    props: {
        number: {
            tyep: Number,
            default: 1
        },
        goodsSkuDetail: {
            tyep: Object,
            default: () => {}
        },
        size: {
            tyep: String,
            default: "mini"
        },
        sale_control_name: {
            tyep: String,
            default: ""
        },
        is_member_by: {
            tyep: Boolean,
            default: false
        }
    },
    data() {
        return {
            numbers: this.number
        }
    },
    watch: {
        number(val, neval) {
            this.numbers = val
            this.$emit("upnumber", val)
        }
    },
    methods: {
        shareOclick() {
            if (!this.$store.getters.token) this.$router.pushToTab("/login")
        },
        hangenumbers(e) {
            if(e !=undefined){
                this.numbers = e
            }else{
                this.numbers  = this.goodsSkuDetail.min_buy

            }
            this.$emit("upnumber",this.numbers)
        }
    }
}
</script>

<style lang="scss" scoped>
// ::v-deep .el-input__inner:focus {
//     border-color: #4caf50 !important;
// }
// ::v-deep .el-input-number__increase:hover {
//     color: #4caf50 !important;
// }
// ::v-deep .el-input-number__increase:hover,
// .el-input-number__decrease:hover {
//     color: #4caf50 !important;
// }
</style>
