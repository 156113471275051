<template>
    <div class="goods-info">
        <div class="img-wrap">
            <img class="img-thumbnail" :src="$img(goods.sku_image, { size: 'mid' })" @error="imageError()" />
        </div>
        <div class="info-wrap">
            <h5>{{ goods.sku_name }} <slot name="title-after"></slot></h5>
            <div class="product-attr">
                <p class="el-space">
                    <span>规格：{{ goods.extension_data.spec || '无' }}</span>
                    <!-- <span>效期：{{ goods.extension_data.expiry_date }}</span> -->
                    <span>单位：{{ goods.unit || '无' }}</span>
<!--                    <span>中包装：{{ goods.extension_data.min_buy || '无' }}</span>-->
                    <!-- <span>库存：{{ stockCompute }}</span> -->
                </p>
                <p><span>{{ goods.extension_data.factory }}</span></p>
            </div>
        </div>
        <div>
            <template v-if="sku_spec_format.length > 0">
                <span v-for="(x, i) in sku_spec_format" :key="i">
                    {{ x.spec_name }}：{{ x.spec_value_name }} {{ i < goods.sku_spec_format.length - 1 ? '；' : '' }} </span>
            </template>
        </div>
    </div>
</template>

<script>
import itemMixin from '../itemMixin'
export default {
    name: 'goods-info-goods-row',
    mixins: [itemMixin],
    created() {
        // console.log(this.goods)
    }
}
</script>

<style lang="scss" scoped>
.goods-info {
    display: flex;
    .img-wrap {
        width: 80px;
        height: 80px;
        float: left;
        margin-right: 10px;
        cursor: pointer;
    }

    .info-wrap {
        margin-left: 10px;
        cursor: pointer;
        text-align: left;
        h5 {
            font-weight: normal;
            font-size: $ns-font-size-base;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-right: 10px;
            &:hover {
                color: $base-color;
            }
        }

        span {
            font-size: $ns-font-size-sm;
            color: #9a9a9a;
        }
    }
}</style>