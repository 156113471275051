<template>
    <div class="product-info-item" :style="left == 0 ? {width: width + 'px'} : {width: width + 'px', marginLeft:left +'px', marginRight: '0px'}">
        <img
            v-if="img_url"
            :style="{width: width - 20  + 'px', height: width - 20 + 'px'}"
            class="img-wrap"
            :src="$img(img_url, { size: 'mid' })"
            @error="imageError()"
        />
        <div class="product-info">
           
            <div class="price-wrap">
                <el-tag size="mini" class="goods-type" v-if="goodsType">{{ activityMap[goodsType]&&activityMap[goodsType].tagText }}</el-tag>
                <div class="price">
                    <p>￥</p>
                    <template v-if="!$store.getters.token">
                       登录可见
                    </template>
                
                    <template v-else-if="!control.is_member_by">
                        {{control.not_buy_msg}}
                    </template>
                    <template v-else-if="activityMap[goodsType]&&activityMap[goodsType].tagText == '批发'&& goodsType">
                        {{ copy_goods.min_price }}~{{copy_goods.max_price}}
                    </template>
                    <template v-else>
                      {{ copy_goods.discount_price }}
                    </template>
                </div>
                <template v-if="copy_goods.market_price > 0 && $store.getters.token && control.show_price">
                    <div class="market-price">￥{{ copy_goods.market_price }}</div>
                </template>
            </div>
            <div class="goods-name">{{ goodsName }}</div>
           
            <!--		商品标签展示部分-->
            <div class="product-label" v-if="tag_list.length>0">
                <template v-for="(item, index) in tag_list" >
                    <div class="label-item font-size-activity-tag"
                          :style="{border:`1px solid ${item.tag_color}`,color:`${item.tag_color}`}">
                        <span>{{ item.tag_name }}</span>
                    </div>
                </template>
                <template v-if="tag_list.length === 0">
                    <div style="height: 30px;width: 100%"></div>
                </template>
            </div>
            <!--		商品属性部分展示-->
            <ctrlShowText :info="goods" type="custom" padding="0 10px" margin="6px 0" size="12px"
            :wesArr="['规格','效期','厂家']" :traArr="['规格','生产日期','厂家']"></ctrlShowText>
            <div class="sale-num">
                                <p>{{ goods.sale_num || 0 }}</p>人付款
                            </div>
            <div class="saling">
                <div class="free-shipping" v-if="copy_goods.is_free_shipping === 1">包邮</div>
                <div class="is_own" v-if="copy_goods.is_own === 1">自营</div>
                <div class="promotion-type" v-if="copy_goods.promotion_type === 1">限时折扣</div>
            </div>
            <template v-if="control?.need_qualifications && storeToken">
                <div class="upload-qualification" @click.stop="qualificationPage">
                    <div class="upload-btn" @click="qualificationPage">上传资质</div>
                </div>
            </template>
        </div>
       <slot></slot>
    </div>
</template>

<script>
    /**
     * @description 商品信息组件
     * @property {Number} goodsName 商品名称
     * @property {Object} goods 商品
     * @property {String} factory 厂家
     * @property {String} spec 规格
     * @property {String} dosage_form 剂型
     * @property {String,Number} unit_package 件装量
     * @property {String,Number} min_buy 中包装
     * @property {String} expiry_date 效期
     * @property {String,Number} stock 库存
     * @property {Array} tag_list 商品标签
     * @property {Object} control 商品控销 [is_member_buy]是否有权限购买 [show_price]是否显示价格
     * @property {Boolean} onlyAttr 是否只展示商品信息 适配营销活动秒杀（因为秒杀的价格字段取值不一样）
     * @property {String} imgUrl 图片链接
     * @property {Number} width 元素宽
     * @property {String} goodsType 元素宽
     */
    import itemMixin from '../itemMixin'
    import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
    import {onTip} from "@/utils/tips"
    export default {
        name: "goods-info-item",
        mixins: [itemMixin],
        components: {ctrlShowText},
        props: {
            goodsName: {
                type: String,
                default: ''
            },
            factory: {
                type: String,
                default: ''
            },
            spec: {
                type: String,
                default: ''
            },
            unit_package: {
                type: String || Number,
                default: ''
            },
            dosage_form: {
                type: String,
                default: ''
            },
            min_buy: {
                type: String || Number,
                default: ''
            },
            expiry_date: {
                type: String,
                default: ''
            },
            stock: {
                type: Number || String,
                default: 0
            },
            onlyAttr: {
                type: Boolean,
                default: false
            },
            tag_list: {
                type: Array,
                default: []
            },
            control: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            img_url: {
                type: String,
                default: ''
            },
            width: {
                type: Number,
                default: 220
            },
            goodsType:{
                type:String,
                default:''
            },
            left: {
                type: Number,
                default: 0
            },
            goods:{
                type:Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                priceAuthority: true,
            }
        },
        mounted() {

        },
        methods:{
            // onClickPath(val){
            //     this.$router.pushToTab({ path:'/sku?id=' + val })
            // }
        }
        // watch: {
        //     goods: {
        //     	handler(newValue, oldValue) {
        //     		console.log('newValue------', newValue)
        //     	},
        //     	immediate: true
        //     }
        // },

    }
</script>

<style lang="scss" scoped>
.product-info-item {
    width: 220px;
    // margin: 10px 20px 0 0;
    margin-top: 10px;
    margin-right: 20px;
    border: 1px solid #eeeeee;
    padding-top: 10px;
    position: relative;
    padding-bottom: 5px;

    &:hover {
        border: 1px solid $base-color;
    }

    .img-wrap {
        cursor: pointer;
        padding: 10px;
    }

    .goods-name {
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
        // height: 50px;
        cursor: pointer;

        &:hover {
            color: $base-color;
        }
    }

    .price-wrap {
        padding: 0 10px;
        display: flex;
        align-items: center;
        .goods-type {
            margin-right: 5px;
        }
        .price {
            display: flex;
            color: $base-color;
            font-size: $ns-font-size-lg;

            p {
                font-size: $ns-font-size-base;
                display: flex;
                align-items: flex-end;
            }
        }

        .market-price {
            color: #838383;
            text-decoration: line-through;
            margin-left: 10px;
        }
    }

    .sale-num {
        padding: 0 10px;
        display: flex;
        color: #838383;
        font-size: $ns-font-size-sm;

        p {
            color: #4759a8;
        }
    }

    .shop_name {
        padding: 0 10px;
        display: flex;
        color: #838383;
    }

    .saling {
        padding: 0 10px;
        display: flex;
        font-size: $ns-font-size-sm;
        line-height: 1;
         height: 25px;
        .free-shipping {
            background: $base-color;
            color: #ffffff;
            padding: 3px 4px;
            border-radius: 2px;
            margin-right: 5px;
        }

        .is_own {
            color: #ffffff;
            background: #ff850f;
            border: 1px solid;
            margin-right: 5px;
            display: flex;
            align-items: center;
            padding: 3px 4px;
            border-radius: 2px;
        }

        .promotion-type {
            color: $base-color;
            border: 1px solid $base-color;
            display: flex;
            align-items: center;
            padding: 1px 3px;
        }
    }

    .item-bottom {
        display: flex;
        margin-top: 5px;

        .collection {
            flex: 1;
            border: 1px solid #e9e9e9;
            border-right: none;
            border-left: none;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .cart {
            flex: 2;
            border: 1px solid #e9e9e9;
            border-right: none;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
    .product-info{
        position: relative;
    
        .upload-qualification {
            cursor: pointer;
            position: absolute;
            right: 2px;
            bottom: 0;
    
            .upload-btn {
                font-size: $ns-font-size-sm;
                color: $base-color;
                padding: 2px 4px;
                border: 1px solid $base-color;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .goods-name {
            padding: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            //line-height: unset;
            line-height: 1.3;
            // height: 40px;
            cursor: pointer;
    
            &:hover {
                color: $base-color;
            }
        }
        .sale-num {
            padding: 0 10px;
            display: flex;
            color: #838383;
            font-size: $ns-font-size-sm;
    
            p {
                color: #4759a8;
            }
        }
        .shop_name {
            padding: 0 10px;
            display: flex;
            color: #838383;
        }
        .price-wrap {
            padding: 0 10px;
            display: flex;
            align-items: center;
    
            .price {
                display: flex;
                color: $base-color;
                font-size: $ns-font-size-lg;
    
                p {
                    font-size: $ns-font-size-base;
                    display: flex;
                    align-items: flex-end;
                }
            }
    
            .market-price {
                color: #838383;
                text-decoration: line-through;
                margin-left: 10px;
            }
        }
        .saling {
            padding: 5px 10px;
            display: flex;
            font-size: $ns-font-size-sm;
            line-height: 1;
    
            .free-shipping {
                background: $base-color;
                color: #ffffff;
                padding: 6px 4px !important;
                border-radius: 2px;
                margin-right: 5px;
            }
    
            .is_own {
                color: #ffffff;
                background: #ff850f;
                border: 1px solid;
                margin-right: 5px;
                display: flex;
                align-items: center;
                padding: 3px 4px;
                border-radius: 2px;
            }
    
            .promotion-type {
                color: $base-color;
                border: 1px solid $base-color;
                display: flex;
                align-items: center;
                padding: 1px 3px;
            }
        }
        .product-label {
            margin: 0 5px;
            //margin: 0 -10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
    
            .label-item {
                font-size: $ns-font-size-sm;
                margin: 0 5px;
                margin-bottom: 6px;
                padding: 0 6px;
                border-radius: 4px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                   text-align: center;
                    line-height: 20px;
                }
            }
        }
    
        .product-attr{
            margin: 0 10px;
            div{
                color: #838383;
                font-size: $ns-font-size-sm;
                line-height: 1.4;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                word-break: break-all;
            }
        }
    }
}
</style>