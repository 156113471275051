<template>
    <div class="list">
        <div class="list-wrap">
            <!-- 导航切换的列表 -->
            <div class="screelist2-nv">
                <ul style="display: flex">
                    <li
                        v-for="(item,index) in subCategory"
                        :key="index"
                        @click="onclickData(item.id)"
                    >
                        <div>
                            <el-image :src="$util.img(item.logo)" fit="cover"></el-image>
                        </div>
                    </li>
                </ul>
            </div>
            <template v-if="cargoList.length">
                <div class="goods-recommended" v-if="cargoList.length">
                    <goods-recommend :page-size="cargoList.length < 5 ? 2 : 5" />
                </div>
                <div class="sort">
                    <div class="item" @click="changeSort('')">
                        <div class="item-name">综合</div>
                    </div>
                    <div class="item" @click="changeSort('sale_num')">
                        <div class="item-name">销量</div>
                        <i
                            v-if="filters.order === 'sale_num' && filters.sort === 'desc'"
                            class="el-icon-arrow-down el-icon--down"
                        />
                        <i v-else class="el-icon-arrow-up el-icon--up" />
                    </div>
                    <div class="item" @click="changeSort('discount_price')">
                        <div class="item-name">价格</div>
                        <i
                            v-if="filters.order === 'discount_price' && filters.sort === 'desc'"
                            class="el-icon-arrow-down el-icon--down"
                        />
                        <i v-else class="el-icon-arrow-up el-icon--up" />
                    </div>
                    <!-- <div class="item-other">
                            <el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
                        </div>
                        <div class="item-other">
                            <el-checkbox label="自营" v-model="is_own"></el-checkbox>
                    </div>-->
                    <div class="input-wrap">
                        <div class="price_range">
                            <el-input placeholder="最低价格" size="small" v-model="filters.min_price"></el-input>
                            <span>—</span>
                            <el-input placeholder="最高价格" size="small" v-model="filters.max_price"></el-input>
                        </div>
                        <el-button plain size="mini" @click="handlePriceRange">确定</el-button>
                    </div>
                </div>
                <div style="display: flex; flex-wrap: wrap;">
                    <goods-info-item
                        v-for="(item, index) in cargoList"
                        :key="item.goods_id"
                        :goods-name="item.goods_name"
                        :goods="item"
                        :factory="item.attr.attr_factory"
                        :spec="item.attr.attr_specs"
                        :dosage_form="item.attr.dosage_form"
                        :unit_package="item.attr.unit_package"
                        :min_buy="item.attr.min_buy"
                        :expiry_date="item.attr.attr_validity"
                        :stock="item.stock"
                        :tag_list="item.tag_list?item.tag_list : []"
                        :control="item.sale_control"
                        :img_url="item.sku_image"
                        @click.native="goSku(item)"
                    >
                        <!-- @click.native="$router.pushToTab({ path: '/sku?id=' + item.sku_id })" -->
                        <!-- <div class="item-bottom">
                                    <div v-if="!item.isCollection" class="collection" @click.stop="editCollection(item)">
                                    <i class="iconfont iconlike"></i>
                                    收藏
                                    </div>
                                    <div v-if="item.isCollection" class="collection" @click.stop="editCollection(item)">
                                    <i class="iconfont iconlikefill ns-text-color"></i>
                                    取消收藏
                                    </div>
                                    <div class="cart" @click.stop="addToCart(item)">
                                    <i class="el-icon-shopping-cart-2"></i>
                                    加入购物车
                                    </div>
                        </div>-->
                        <div class="cart-layout">
                        <div>
                            <shareCounter
                                :number="item.min_buy"
                                :goodsSkuDetail="{
                                        min_buy:item.min_buy,
                                        stock:item.goods_stock,
                                        package_num:item.package_num  || item.min_buy ,
										is_member_by:item.sale_control?.is_member_by,
										view_price :item.sale_control?.view_price,
                                        sale_control_name:item.sale_control?.sale_control_name 
                                    }"
                                size="mini"
                                @upnumber="upnumber"
                            ></shareCounter>
                        </div>
                        <div
                            class="cart-add"
                          :class="item.sale_control && item.sale_control?.view_price ? 'cart-add' : 'ban'"
                            @click.stop="joinCart(item)"
                        >加入购物车</div>
                    </div>
                    </goods-info-item>
                </div>
                <div style="display: flex; justify-content: flex-end">
                    <el-pagination
                        style="padding-right: 30px; margin-top: 20px"
                        background
                        :pager-count="5"
                        :total="total"
                        prev-text="上一页"
                        next-text="下一页"
                        :current-page.sync="currentPage"
                        :page-size.sync="pageSize"
                        @size-change="handlePageSizeChange"
                        @current-change="handleCurrentPageChange"
                    ></el-pagination>
                </div>
                <!-- 悬浮购物车 -->
                <div class="cart-float-btn" v-if="show_shopping" @click="$router.push('/cart')">
                    <div class="cart-icon">
                        <img
                            src="https://manage.2myxc.com//upload/default/diy_view/bottom/cart_selected.png"
                            mode="aspectFill"
                        />
                        <span class="cart-count">{{cartCount}}</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="empty">
                    <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import GoodsRecommend from "@/components/GoodsRecommend"
import {mapGetters} from "vuex"
import {goodsInfoItem } from "@/components/goods-info/index"
import { goodsSkuPage } from "@/api/goods/goods"
import { onTip } from "@/utils/tips"
import shareCounter from "@/components/shareCounter.vue"
export default {
    components: {
        goodsInfoItem,
        GoodsRecommend,
        shareCounter
    },
    props: {
        subCategory: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            currentPage: 1,
            pageSize: 12,
            total: 0,
            keyword: "",
            loading: true,
            cargoList: [],
            show_shopping: false, //显示购物车
            filters: {
                site_id: 0,
                category_id: 0,
                category_level: 0,
                brand_id: 0,
                tag_id: 0,
                min_price: "",
                max_price: "",
                order: "",
                sort: "desc",
                platform_coupon_type: 0
            },
            brandStr: "",
            number: 1
        }
    },
    computed: {
        ...mapGetters(["defaultBrandImage", "token", "storeWithoutAuthority"])
    },
    created() {
        this.keyword = this.$route.query.keyword || ""
        this.filters.category_id = this.$route.query.category_id || ""
        this.filters.category_level = this.$route.query.level || ""
        this.filters.brand_id = this.$route.query.brand_id || ""
        this.filters.coupon = this.$route.query.coupon || 0
        this.filters.tag_id = this.$route.query.tag_id || ""
        this.viewStyle = this.$route.query.wbstyle || 1
        this.filters.platform_coupon_type = this.$route.query.platform_coupon_type || ""
        this.keyword = this.$route.query.keyword || ""
        this.getGoodsList()
        // 首页搜索是否显示购物车
        if (this.keyword) {
            this.show_shopping = true
        }
    },
    watch: {
        $route: function(curr) {
            if (curr.query.keyword === undefined) {
                this.keyword = ""
            } else {
                this.show_shopping = true
            }
            if (curr.query.level) {
                this.filters.category_level = curr.query.level
                this.filters.category_id = curr.query.category_id
                this.filters.tag_id = curr.query.tag_id
                this.viewStyle = this.$route.query.wbstyle || 1
                this.getGoodsList()
                // if (curr.query.level != 3) {
                //     this.categorySearch()
                // }
            }
        }
    },

    methods: {
        upnumber(val) {
            this.number = val
        },
              // 加入购物车
              joinCart(item) {
			if (onTip(item.sale_control.view_price, item.sale_control.is_member_by,this,item.sale_control.not_buy_msg)) return
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        },
        onclickData(val) {
            this.filters.brand_id = Number(val)
            this.getGoodsList()
        },
        handlePriceRange() {
            this.getGoodsList()
        },
        goSku(item) {
            if (!this.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.sale_control.view_price, item.sale_control.is_member_by,this,item.sale_control.not_buy_msg)) return
           this.$router.pushToTab("/sku-" + item.sku_id)
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.getGoodsList()
        },
        handleCurrentPageChange(page) {
            window.scrollTo({
                top: document.querySelector(".screelist2-nv").offsetTop - 10
            })
            this.currentPage = page
            this.getGoodsList()
        },
        getGoodsList() {
            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                site_id: this.filters.site_id,
                keyword: this.keyword,
                factory: "",
                brand_name: this.brandStr,
                spec: "",
                dosage_form: "",
                attr: "",
                ...this.filters
            }
            goodsSkuPage(params || {})
                .then(res => {
                    const { count, page_count, list } = res.data
                    this.total = count
                    // 加入需要加入购物车的数量1
                    list.forEach(item => {
                        item.num = 1
                    })
                    this.cargoList = list
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },
        changeSort(type) {
            if (this.filters.order === type) {
                this.$set(this.filters, "sort", this.filters.sort === "desc" ? "asc" : "desc")
            } else {
                this.$set(this.filters, "order", type)
                this.$set(this.filters, "sort", "desc")
            }

            this.getGoodsList()
        }
    }
}
</script>

<style lang="scss" scoped>
.list {
    height: 100%;
    &-wrap {
        overflow: hidden;
    }
}

.cart-float-btn {
    position: fixed;
    right: 55px;
    bottom: 180px;
    width: 50px;
    height: 50px;
    /* background-color: #ffebf9; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e29ea33b;
    z-index: 999;
    opacity: 0.8;
    .cart-icon {
        width: 20px;
        height: 20px;
        /* background-image: url("path/to/cart-icon.png"); */
        background-size: cover;
        position: relative;
        text-align: center;
        line-height: 20px;
    }
    .cart-icon img {
        width: 100%;
        height: 100%;
    }
    .cart-count {
        position: absolute;
        right: -19px;
        top: -20px;
        background-color: #ff0000;
        color: #ffffff;
        font-size: 12px;
        padding: 4px 4px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        text-align: center;
        line-height: 10px;
    }
}
::v-deep .el-input-number--mini{
    width: 90px;
}   
.cart-layout {
                padding: 0 19px;
                display: flex;
                justify-content: center;
                align-items: center;
                .el-input-number--mini {
                    width: 85px !important;
                }
                .cart-num {
                    margin-right: 10px;
                    position: relative;
                    width: 30%;
                    border-radius: 4px;
                    display: flex;
                    border: 1px solid #ccc;
                    justify-content: center;
                    align-items: center;
                    span {
                        z-index: 1;
                        position: absolute;
                        cursor: pointer;
                        width: 20px;
                        text-align: center;
                    }
                    span:first-child {
                        left: 0;
                    }
                    span:last-child {
                        right: 0;
                    }
                    .number {
                        .el-input {
                            .el-input__inner {
                                text-align: center;
                            }
                        }
                    }
                }
                .cart-add {
                    font-size: 12px;
                    margin-left: 5px;
                    cursor: pointer;
                    background: $base-color;
                    color: $base-color-whtie;
                    border-radius: 15px;
                    padding: 2px 10px;
                }
                .ban{
                    font-size: 12px;
                    margin-left: 5px;
                    cursor: not-allowed;
                    background:#dddddd6e;
                    color: $base-color-whtie;
                    border-radius: 15px;
                    padding: 2px 10px;
                }
            }
.screelist2-nv {
    margin: 20px 0;
    
    ul {
        padding-right: 40px;
        width: 1200px;
        box-sizing: border-box;
        justify-content: space-between;
        margin: 0 auto;
    }
    li {
        // margin-right: 40px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
            opacity: 0.7;
        }
        .el-image {
            width: 100px;
            height: 100px;
        }
        .nv-name {
            font-weight: bold;
            text-align: center;
            color: #303133ed;
        }
    }
}
.goods-recommended {
    width: 200px;
    background-color: #fff;
    float: left;
    margin-right: 10px;
}
.screelist2-nv {
    margin: 20px 0;
    ul {
        padding-right: 40px;
        width: 1200px;
        box-sizing: border-box;
        justify-content: space-between;
        margin: 0 auto;
    }
    li {
        // margin-right: 40px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
            opacity: 0.7;
        }
        .el-image {
            width: 100px;
            height: 100px;
        }
        .nv-name {
            font-weight: bold;
            text-align: center;
            color: #303133ed;
        }
    }
}
.sort {
    display: flex;
    align-items: center;

    .item {
        display: flex;
        align-items: center;
        padding: 5px 15px;
        border: 1px solid #f1f1f1;
        border-left: none;
        cursor: pointer;

        &:hover {
            background: $base-color;
            color: #fff;
        }
    }

    .item-other {
        display: flex;
        align-items: center;
        padding: 5px 15px;
        border: 1px solid #f1f1f1;
        border-left: none;
        cursor: pointer;
    }

    .input-wrap {
        display: flex;
        align-items: center;

        .price_range {
            margin-left: 60px;
        }

        span {
            padding-left: 10px;
        }

        .el-input {
            width: 150px;
            margin-left: 10px;
        }

        .el-button {
            margin: 0 17px;
        }
    }

    > div:first-child {
        border-left: 1px solid #f1f1f1;
    }
}
</style>