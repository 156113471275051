import * as seckillApi from "@/api/promotion/seckill"
import * as wholesaleApi from "@/api/promotion/wholesale"
import * as groupbuyApi from "@/api/promotion/groupbuy"
import * as getfreeApi from "@/api/promotion/getfree"
import * as discountApi from "@/api/promotion/discount"
import * as bundlingApi from "@/api/promotion/bundling"

/*
* listApi 列表接口
* price
*   market_price 划线
*   discount_price 标
*
* sessionInfo 场次信息
*   key: 场次id字段名称
*   getId 获取场次id值
* tagText 显示标签
* priceText 实际价格label
*
* getDetailUrl 获取详情页链接
* detail 详情页参数
*   request 详情接口
*   paramsKeys 接口传参字段
*   init this指向 -> 活动页活动组件实例， 用于修改数量
* promotion 加入购物车传参
*   promotion_type 商品活动类型
*   getPromotion_id（detail : goods详细） 获取活动id
* payment 结算页传参
*   payment paymentApi,
*   calculate calculateApi,
*   orderCreate orderCreateApi,
*   paramsKeys 接口传参字段
* */
export default {
    seckill: {
        type: 'seckill',
        listApi: seckillApi.goodsPage,
        listItemAdepter(item){
            return {
                ...item,
                promotion_type: 1,
                promotion_label: 'seckill',
                member_price: item.price,
                discount_price: item.seckill_price
            }
        }
    },
    discount: {
        type: 'discount',
        listApi: discountApi.goodsPage,
        listItemAdepter(item){
            return {
                ...item,
                promotion_type: 1,
                promotion_label: 'discount',
                member_price: item.member_price,
                discount_price: item.discount_price
            }
        }
    },
    wholesale: {
        type: 'wholesale',
        listApi: wholesaleApi.goodsPage,
        price: {
            market_price: 'price',
            discount_price: 'min_price'
        },
        // 加入购物车传参
        promotion: {
            promotion_type: 'wholesale',
            getPromotion_id(detail) {
                return detail.wholesale_sku_id
            }
        },
    },
    groupbuy: {
        type: 'groupbuy',
        tagText: '团购',
        priceText: '团购价',
        listApi: groupbuyApi.goodsPage,
        price: {
            market_price: 'price',
            discount_price: 'groupbuy_price'
        },
        promotion: {
            promotion_type: 'groupbuy',
            getPromotion_id(detail) {
                return detail.groupbuy_id
            }
        },
    },
    getfree: {
        type: 'getfree',
        listApi: getfreeApi.goodsPage,
    },
    bundling: {
        type: 'bundling',
        listApi: bundlingApi.goodsPage,
        // listItemAdepter(item){
        //     return {
        //         ...item,
        //         promotion_type: 1,
        //         promotion_label: 'combo',
        //         member_price: item.member_price,
        //         discount_price: item.discount_price
        //     }
        // }
    },
}
