import { mapGetters } from "vuex"
import activityMap from '@/utils/activityMap'

export default {
    props: {
        goods: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            copy_goods: {},
            activityMap,
            sku_spec_format:[]
        }
    },
    created() {
        this.copy_goods = JSON.parse(JSON.stringify(this.goods));
        const activityMapItem = activityMap[this.goodsType]
        if (this.goodsType !== "sku" && this.goodsType) {
            this.copy_goods.market_price = this.copy_goods[activityMapItem.price.market_price] || 0;
            this.copy_goods.discount_price = this.copy_goods[activityMapItem.price.discount_price] || 0;
        }
        // 返回json数据时进行处理
        if(typeof(this.goods.sku_spec_format) == 'string'){
            this.sku_spec_format= this.goods.sku_spec_format ? JSON.parse(String(this.goods.sku_spec_format)) : [] 
        }else{
            this.sku_spec_format = this.goods.sku_spec_format
        }
    },
    computed: {
        storeToken() {
            return this.$store.state.member.token
        },
        storeWithoutAuthority() {
            return this.$store.state.member.storeWithoutAuthority
        },
        storeWithoutLoginTip() {
            return this.$store.state.member.withoutLoginPriceTip
        },
        stockCompute() {
            const { shop_info } = this.goods
            return this.stock >= shop_info.max_stock ? (shop_info.max_stock_label ? shop_info.max_stock_label : this.stock) : this.stock
        },
        ...mapGetters([
            "defaultGoodsImage"
        ])
    },
    methods: {
        qualificationPage() {
            this.$router.push("/member/certiys")
        },
        imageError() {
            this.goods.sku_image = this.defaultGoodsImage
        }
    },
    filters: {
        filterPrice(data) {
            let priceArr = Number(data).toString().split(".")
            return priceArr[1] ? ("." + priceArr[1]) : ""
        }
    }
}